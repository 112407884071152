/* eslint-disable security/detect-object-injection */
// data validated in AEM
import { createMediaQuery, getBackgroundColor } from '@oneaudi/aoa-shared-editorial-components';
import styled from 'styled-components';

const alignmentMappings = {
  horizontal: {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  },
  vertical: {
    top: 'flex-start',
    center: 'center',
    bottom: 'flex-end',
  },
};

export const Container = styled.div`
  background-color: ${getBackgroundColor({ light: 'white', dark: 'black' })};
  display: flex;
  flex: 1;
  height: 0;
  ${({ mobileBreakpointSizes }) => `padding-top: ${mobileBreakpointSizes.s}%;`}
  overflow: hidden;
  position: relative;

  ${createMediaQuery({
    m: ({ mobileBreakpointSizes }) => ({ 'padding-top': `${mobileBreakpointSizes.m}%` }),
    l: ({ size, index, layoutName }) => {
      if (index === 2 && layoutName === 'Irregular - 2x1 split column right') {
        return { 'padding-top': `calc(${size}% + 12px)` };
      }
      if (index === 2 && layoutName === 'Irregular - 2x1 split column left') {
        return { 'padding-top': `calc(${size}% + 12px)` };
      }
      if (layoutName === 'Irregular - 2x1 wide') {
        return { 'padding-top': `${size}vw` };
      }
      return size === null ? { height: '100%', 'padding-top': 0 } : { 'padding-top': `${size}%` };
    },
  })}

  // parallax effect on hover
  &:hover picture > img, &:hover video {
    ${(props) => (props.zoomEnabled ? 'transform: scale(1.2);' : '')}
    ${(props) => (props.zoomEnabled ? `transition: all 5000ms cubic-bezier(0.75,0.02,0.5,1);` : '')}
  }
`;

export const StyledAnchor = styled.a`
  display: block;
  text-decoration: none;
`;

export const InnerWrapper = styled.div`
  bottom: 0;
  display: flex;
  left: 0;
  ${({
    horizontalAlignmentSmall,
    horizontalAlignment,
    verticalAlignmentSmall,
    verticalAlignment,
  }) =>
    createMediaQuery({
      xs: {
        'align-items': alignmentMappings.vertical[verticalAlignmentSmall],
        'justify-content': alignmentMappings.horizontal[horizontalAlignmentSmall],
      },
      l: {
        'align-items': alignmentMappings.vertical[verticalAlignment],
        'justify-content': alignmentMappings.horizontal[horizontalAlignment],
      },
    })}
  position: absolute;
  right: 0;
  top: 0;
`;

export const MediaContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: absolute;
  transition: all 2000ms ${(p) => p.theme.easing};
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px;
  position: relative;
`;

export const NoAnchorContainer = styled.div``;

export const LogoContainer = styled.div`
  text-align: ${({ horizontalAlignment }) => horizontalAlignment};
`;

export const Logo = styled.img`
  margin-bottom: 12px;
  width: 110px;
`;

const iconSizes = {
  xs: '24px',
  l: '48px',
};

export const Icon = styled.div`
  color: white;
  margin: ${({ horizontalAlignmentSmall }) =>
    horizontalAlignmentSmall === 'left' || horizontalAlignmentSmall === 'right' ? '0' : '0 auto'};

  ${createMediaQuery({
    m: {
      margin: ({ horizontalAlignment }) =>
        horizontalAlignment === 'left' || horizontalAlignment === 'right' ? '0' : '0 auto',
    },
  })}

  margin-bottom: 12px;

  ${createMediaQuery({
    width: iconSizes,
  })};

  > svg {
    display: block;
    ${createMediaQuery({
      height: iconSizes,
      width: iconSizes,
    })};
  }
`;

export const Heading = styled.div`
  text-align: ${({ horizontalAlignment }) => horizontalAlignment};
`;

export const SubHeading = styled.div`
  margin: 0;
  margin-top: 12px;
  text-align: ${({ horizontalAlignment }) => horizontalAlignment};
`;

export const CTAsContainer = styled.div`
  margin-top: 28px;
  text-align: ${({ horizontalAlignment }) => horizontalAlignment};
`;

export const DisclaimerWrapper = styled.span`
  sup a {
    text-decoration: none;
  }
`;
