export const objToStr = (obj, delimiter = '&') => {
  if (!obj) {
    return '';
  }
  const attrs = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    attrs.push(`${key}=${value.replace(/"/g, "'")}`);
  }
  return attrs.join(delimiter);
};
